import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";

import Switcher from "../component/Switcher";
import { LuSearch } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { enquiry, getPropertyList } from "../actions/PropertyActions";
import Loader from "../component/Loader";
import { Link } from "react-router-dom";
import { LiaCompressArrowsAltSolid } from "react-icons/lia";
import { BiMap } from "react-icons/bi";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

export default function GridSidebar() {
  const dispatch = useDispatch();
  const { propertyList, loading, isEnquirySuccess } = useSelector(
    (state) => state.propertyState
  );
  let items = propertyList;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [offer, setOffer] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [residentialStatus, setResidentialStatus] = useState("");
  const [residentialType, setResidentialType] = useState("");
  const [limit, setLimit] = useState(12);
  const [startIndex, setStartIndex] = useState(9);
  // const [sort, setSort] = useState("createdAt");
  // const [order, setOrder] = useState("desc");
  // const [type, setType] = useState("");
  const [state, setState] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [passingId, SetPassingId] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const setCurrentPageNo = (pageNo) => {
    setCurrentPage(pageNo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(enquiry(passingId, formData));
    SetPassingId("");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setStartIndex(0);
    dispatch(
      getPropertyList(
        searchTerm,
        offer,
        propertyStatus,
        propertyType,
        residentialStatus,
        residentialType,
        limit,
        state
      )
    );

    setSearchTerm("");
  };

  const enquireHandler = (e, id) => {
    e.target.disabled = true;
    setOpen(true);
    SetPassingId(id);
  };

  useEffect(() => {
    setStartIndex(currentPage * limit - limit);
  }, [currentPage, limit]);

  useEffect(() => {
    if (startIndex >= 0) {
      dispatch(
        getPropertyList(
          searchTerm,
          offer,
          propertyStatus,
          propertyType,
          residentialStatus,
          residentialType,
          limit,
          startIndex
        )
      );
    }
  }, [dispatch, startIndex]);

  useEffect(() => {
    setOpen(false);
  }, [isEnquirySuccess]);

  let totalPages = 5;
  const generatePages = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  // console.log('start index', currentPage * limit - limit)
  return (
    <>
      <Navbar />
      {/* <section className="relative table w-full py-32 lg:py-36 bg-[url('../../assets/images/bg/01.jpg')] bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">Grid View Layout</h3>
                    </div>
                </div>
            </section> */}
      {/* <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div> */}

      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-4 md:col-span-6">
              <div className="shadow dark:shadow-gray-700 p-6 rounded-xl bg-white dark:bg-slate-900 sticky top-20">
                <form onSubmit={submitHandler}>
                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <label htmlFor="searchname" className="font-medium">
                        Search Properties
                      </label>
                      <div className="relative mt-2">
                        <LuSearch className="text-lg absolute top-[8px] start-3" />
                        <input
                          name="search"
                          id="searchname"
                          type="text"
                          className="form-input border border-slate-100 dark:border-slate-800 ps-10"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="font-medium">Categories</label>
                      <select
                        value={propertyType}
                        onChange={(e) => {
                          setPropertyType(e.target.value);
                        }}
                        className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                      >
                        <option value="">Select</option>
                        <option value="Residential">Residential</option>
                        <option value="Land">Land</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Industrial">Industrial</option>
                        <option value="Agricultural">Agricultural</option>
                      </select>
                    </div>

                    {/* <div>
                      <label className="font-medium">Location</label>
                      <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1">
                        <option value="NY">New York</option>
                        <option value="MC">North Carolina</option>
                        <option value="SC">South Carolina</option>
                      </select>
                    </div> */}

                    <div>
                      <input
                        type="submit"
                        className="btn bg-green-600 filterBtn text-white rounded-md w-full cursor-pointer"
                        value="Apply now"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="lg:col-span-8 md:col-span-6">
              {loading ? (
                <Loader />
              ) : items && items.length > 0 ? (
                <>
                  <div className={"grid grid-cols-1 gap-[30px] "}>
                    {items &&
                      items.map((item, index) => (
                        <div
                          key={index}
                          className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500 w-full mx-auto"
                        >
                          <div className="md:flex">
                            <div className="relative md:shrink-0">
                              <img
                                className="h-full w-full object-cover md:w-48"
                                src={item.imageUrls[0].image}
                                alt=""
                              />
                            </div>
                            <div className="p-6 w-full">
                              <div className="md:pb-4 pb-6">
                                <Link
                                  to={`/property-detail/${item._id}`}
                                  className="text-lg mainTextHover font-medium ease-in-out duration-500"
                                >
                                  {item.name}
                                </Link>
                              </div>

                              <ul className="md:py-4 py-6 border-y border-slate-100 dark:border-gray-800 flex items-center justify-between list-none">
                                <li className="flex items-center me-4">
                                  <LiaCompressArrowsAltSolid className="text-2xl me-2 text-green-600" />
                                  <span>{item.square}sqf</span>
                                </li>

                                <li className="flex items-center me-4">
                                  <BiMap className="text-2xl me-2 text-green-600" />
                                  <span>{item.state}</span>
                                </li>

                                {/* <li className="flex items-center">
                                <LiaBathSolid className="text-2xl me-2 text-green-600" />
                                <span>{item.baths} Baths</span>
                              </li> */}
                              </ul>

                              <ul className="md:pt-4 pt-6 flex justify-between items-center list-none">
                                <li>
                                  <span className="text-slate-400">Price</span>
                                  <p className="text-lg font-medium">
                                    ₹&nbsp;{item.regularPrice}
                                  </p>
                                </li>

                                <li>
                                  <button
                                    onClick={(e) => enquireHandler(e, item._id)}
                                    className="bg-transparent callBackBtn text-green-600 font-semibold py-2 px-4 border border-green-600 rounded"
                                  >
                                    Get a call back
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  
                </>
              ) : (
                <>
                  <div className="flex items-center justify-center">
                    <div className="grid grid-cols-1 gap-10 text-center">
                      <div className="flex flex-col items-center justify-center">
                        <h1 className="text-2xl font-semibold text-gray-700">
                         Oops No Properties Found
                        </h1>
                        <p className="text-gray-500">
                          We couldn’t find any properties matching your search
                          criteria.
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}


<div className="grid md:grid-cols-6 grid-cols-1 mt-20 w-full">
                    <div className="md:col-span-12 flex justify-center">
                      <nav className="flex items-center space-x-2">
                        {/* Previous Button */}
                        <Link
                          key={`prev-${currentPage}`}
                          onClick={() =>
                            setCurrentPageNo(
                              currentPage > 1 ? currentPage - 1 : 1
                            )
                          }
                          className={`size-10 inline-flex justify-center items-center mx-1 rounded-full 
                               text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm 
                                dark:shadow-gray-700 hover:border-green-600 dark:hover:border-green-600 
                              hover:bg-gray-700 dark:hover:bg-green-600 ${
                                currentPage === 1
                                  ? "text-gray-400 cursor-not-allowed"
                                  : "text-green-600 hover:bg-gray-700"
                              }`}
                          style={{
                            pointerEvents: currentPage === 1 ? "none" : "auto",
                          }}
                        >
                          &lt;
                        </Link>

                        {/* Page Numbers */}
                        {generatePages().map((page) => (
                          <Link
                            key={page}
                            onClick={() => setCurrentPageNo(page)}
                            className={`px-3 py-2 border rounded ${
                              page === currentPage
                                ? "z-10 size-10 inline-flex justify-center items-center mx-1 rounded-full text-white bg-gray-600 shadow-sm"
                                : "size-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 hover:text-white bg-white dark:bg-slate-900 shadow-sm dark:shadow-gray-700 hover:border-gray-600 dark:hover:border-green-600 hover:bg-gray-600 dark:hover:bg-green-600"
                            }`}
                          >
                            {page}
                          </Link>
                        ))}

                        {/* Next Button */}
                        <Link
                          key={`next-${currentPage}`}
                          onClick={() =>
                            setCurrentPageNo(
                              currentPage < totalPages
                                ? currentPage + 1
                                : totalPages
                            )
                          }
                          className={`size-10 inline-flex justify-center items-center mx-1 rounded-full 
                         text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm 
                          dark:shadow-gray-700 hover:border-gray-600 dark:hover:border-green-600 
                      hover:bg-gray-700 dark:hover:bg-green-600 ${
                        currentPage === totalPages
                          ? "text-gray-400 cursor-not-allowed"
                          : "text-green-600 hover:bg-gray-700"
                      }`}
                          style={{
                            pointerEvents:
                              currentPage === totalPages ? "none" : "auto",
                          }}
                        >
                          &gt;
                        </Link>
                      </nav>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
      <Footer />
      <Switcher />

      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto P-5">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0 ">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-5"
            >
              <div className="bg-white px-6 pt-5 pb-4 sm:p-6">
                <div className="sm:flex sm:items-center flex justify-center mb-4">
                  <DialogTitle
                    as="h2"
                    className="ml-4 text-2xl font-bold text-gray-700"
                  >
                    GET A CALL BACK.
                  </DialogTitle>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4 ">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name ..."
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email ..."
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Mobile ..."
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="flex justify-end space-x-3 mt-5">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className="inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex filterBtn justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
