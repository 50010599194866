import React from "react";
import { Link } from "react-router-dom";
import LogoLight from "../assets/images/Mybrokerr-light.png";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { BsPencil } from "react-icons/bs";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FiDribbble, FiFacebook, FiInstagram, FiLinkedin, FiMail, FiMapPin, FiPhone, FiShoppingCart, FiTwitter, FiYoutube } from "react-icons/fi";
import { BiLogoBehance } from "react-icons/bi";

export default function Footer() {

    return (
        <>
            <footer className="relative bg-slate-900 dark:bg-slate-800 mt-24 pt-24">
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="relative py-16">
                            {/* <!-- Subscribe --> */}
                            <div className="relative w-full">


                                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] -mt-24">
                                    <div className="lg:col-span-4 md:col-span-12">
                                        <Link to="#" className="text-[22px] focus:outline-none">
                                            <img src={LogoLight} alt="" />
                                        </Link>
                                        <p className="mt-6 text-gray-300">Delivering quality properties with innovation, reliability, and a commitment to exceptional service, all through our trusted online platform.</p>

                                    </div>

                                    {/* <div className="lg:col-span-2 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Company</h5>
                                        <ul className="list-none footer-list mt-6">
                                            <li className="mt-[10px]"><Link to="/features" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Services</Link></li>
                                            <li className="mt-[10px]"><Link to="/pricing" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Pricing</Link></li>
                                            <li className="mt-[10px]"><Link to="/blog" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Blog</Link></li>
                                            <li className="mt-[10px]"><Link to="/auth-login" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Login</Link></li>
                                        </ul>
                                    </div> */}

                                    <div className="lg:col-span-3 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Usefull Links</h5>
                                        <ul className="list-none footer-list mt-6">
                                        <li><Link to="/aboutus" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> About us</Link></li>
                                            <li className="mt-[10px]"><Link to="/terms" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Terms of Services</Link></li>
                                            <li className="mt-[10px]"><Link to="/privacy" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Privacy Policy</Link></li>
                                            <li className="mt-[10px]"><Link to="/contact" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Contact</Link></li>
                                        </ul>
                                    </div>

                                    <div className="lg:col-span-3 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Contact Details</h5>
                                        <div className="flex mt-6">
                                            <FiMapPin className="size-5 text-green-600 me-3"/>
                                            <div className="">
                                                <h6 className="text-gray-300 mb-2"> Palakkad, <br /> kerala, India</h6>
                                                {/* <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d501701.50443614705!2d76.31350375625001!3d10.769660300000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba86df864f54585%3A0x5495d848c0e86138!2sBizpole-Palakkad!5e0!3m2!1sen!2sin!4v1731572759196!5m2!1sen!2sin" data-type="iframe" className="text-green-600 hover:text-green-700 duration-500 ease-in-out lightbox">View on Google map</Link> */}
                                            </div>
                                        </div>

                                        <div className="flex mt-6">
                                            <FiMail className="size-5 text-green-600 me-3"/>
                                            <div className="">
                                                <Link to="mailto:contact@example.com" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">info@mybrokerr.com</Link>
                                            </div>
                                        </div>

                                        <div className="flex mt-6">
                                            <FiPhone className="size-5 text-green-600 me-3"/>
                                            <div className="">
                                                <Link to="tel:+152534-468-854" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">+152 534-468-854</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Subscribe --> */}
                        </div>
                    </div>
                </div>

                <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
                    <div className="container text-center">
                        <div className="grid md:grid-cols-2 items-center gap-6">
                            <div className="md:text-start text-center">
                                <p className="mb-0 text-gray-300">© <script type="text/javascript" id="www-widgetapi-script" src="https://www.youtube.com/s/player/d87d581f/www-widgetapi.vflset/www-widgetapi.js"></script><script id="iframe_api" src="https://www.youtube.com/iframe_api"></script>{(new Date().getFullYear())}{" "} Mybrokerr. Design & Develop with by <Link to="https://www.bizpole.in/" target="_blank" className="text-reset">Bizpole</Link>.</p>
                            </div>

                            <ul className="list-none md:text-end text-center">
                                <li className="inline ms-1"><Link to="https://youtube.com/@mybrokerr-u1l?si=aLhPjZ6OlNDctT-N" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiYoutube className="size-4"/></Link></li>
                                <li className="inline ms-1"><Link to="https://www.facebook.com/profile.php?id=61568473626505" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiFacebook className="size-4"/></Link></li>
                                <li className="inline ms-1"><Link to="https://www.instagram.com/mybrokerr_/profilecard/?igsh=ZmtzN2lnZHp3NG93" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiInstagram className="size-4"/></Link></li>
                                <li className="inline ms-1"><Link to="mailto:info@mybrokerr.com" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiMail className="size-4"/></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );

}