import {
  enquiryFail,
  enquiryRequest,
  enquirySuccess,
  propertyFail,
  propertyRequest,
  propertySuccess,
  singlePropertyFail,
  singlePropertyRequest,
  singlePropertySuccess,
} from "../slices/propertySlice";
import { apiClient } from "./utils";

export const getProperty = (id) => async (dispatch) => {
  try {
    dispatch(singlePropertyRequest());
    const response = await apiClient.get(`/api/v1/get/${id}`);
    const data = response.data;
    dispatch(singlePropertySuccess(data));
    
  } catch (error) {
    dispatch(singlePropertyFail(error));
  }
};

export const enquiry = (id,details) => async (dispatch) => {
  try {
    dispatch(enquiryRequest());
    const response = await apiClient.post(`/api/v1/enquire/${id}` , details);
    const data = response.data;
    dispatch(enquirySuccess(data));
  } catch (error) {
    dispatch(enquiryFail(error));
  }
};



export const getPropertyList =
  (
    keyword,
    offer,
    propertyStatus,
    propertyType,
    residentialStatus,
    residentialType,
    limit,
    startIndex,
    sort = "createdAt",
    order = "desc",
    type = "Villa"
  ) =>
  async (dispatch) => {
    try {
      dispatch(propertyRequest());

      const params = [];
      console.log('req.query.startIndex frontend', startIndex ,limit )
      if (offer) params.push(`offer=${offer}`);
      if (propertyStatus) params.push(`propertyStatus=${propertyStatus}`);
      if (propertyType) params.push(`propertyType=${propertyType}`);
      if (residentialStatus)
        params.push(`residentialStatus=${residentialStatus}`);
      if (residentialType) params.push(`residentialType=${residentialType}`);
      if (limit) params.push(`limit=${limit}`);
      if (startIndex) params.push(`startIndex=${startIndex}`);
      if (keyword) params.push(`searchTerm=${encodeURIComponent(keyword)}`);
      if (sort) params.push(`sort=${sort}`);
      if (order) params.push(`order=${order}`);
      if (type) params.push(`type=${type}`);

      const queryString = params.length > 0 ? `?${params.join("&")}` : "";

      const link = `/api/v1/get${queryString}`;

      const { data } = await apiClient.get(link);

      dispatch(propertySuccess(data));
    } catch (error) {
      dispatch(propertyFail(error.response.data.message));
    }
  };
